<script lang="ts" setup>
// Komponent odpowiedzialny za wyświetlanie paska informującego o braku połączenia z internetem.

import { useOnline } from '@vueuse/core';

import { usePLKBreakpoints } from '~coreComposables/breakpoints.composable';

const online = useOnline();

const { isMobile } = usePLKBreakpoints();
</script>

<template>
  <Transition>
    <CdsAlert
      v-if="!online"
      class="offline-bar"
      variant="solid"
      type="error"
      size="small"
      icon="lan_error"
      :message="isMobile ? $t('offlineMessageMobile') : $t('offlineMessage')"
    />
  </Transition>
</template>

<style lang="scss" scoped>
.offline-bar {
  width: 100%;
  display: grid;
  row-gap: unset;
  justify-content: center;
}

.v-enter-active {
  animation: slideUpDown 0.5s;
}

.v-leave-active {
  animation: slideUpDown 0.5s reverse;
}

@keyframes slideUpDown {
  from {
    opacity: 0;
    transform: translateY(var(--offline-bar-height));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
